import React, { useEffect } from "react";
import { Image } from "@themesberg/react-bootstrap";

export default (props) => {
  const { show, qrCodeBase64 } = props;

  const overlayStyle = {
    display: show ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 9999,
    transition: "opacity 0.3s ease-in-out",
    opacity: show ? 1 : 0,
  };

  const imageStyle = {
    display: show ? "block" : "none",
  };

  useEffect(() => {
    console.log(qrCodeBase64);
  }, [qrCodeBase64]);

  return (
    <div style={overlayStyle} className={`${show ? "" : "show"}`}>
      <Image
        className="qrcode-element"
        style={imageStyle}
        src={`data:image/png;base64,${qrCodeBase64}`}
        height={300}
      />
    </div>
  );
};
