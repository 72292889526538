import axios from "../api/axios";
import { getCookie, setCookie, COOKIE_NAME } from "../utils/cookieUtils";

export async function refreshToken() {
  try {
    const storedTokens = getCookie(COOKIE_NAME);
    const parsedTokens = JSON.parse(storedTokens);

    if (
      !storedTokens ||
      !parsedTokens.accessToken ||
      !parsedTokens.refreshToken
    ) {
      return null;
    }

    // TODO: pass via header
    const response = await axios.post(
      "auth/Refresh",
      { ...parsedTokens },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + parsedTokens.accessToken,
        },
      }
    );

    // Update the access token in the cookie
    setCookie(COOKIE_NAME, JSON.stringify(response.data));

    // Respond with the new access token
    return response.data.accessToken;
  } catch (error) {
    // Handle error when refreshing the token (e.g., logout the user)
    console.error("Error refreshing access token:", error);
    throw error; // Rethrow the error to reject the promise
  }
}
