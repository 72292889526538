import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
} from "@themesberg/react-bootstrap";
import { PageRoutes } from "../pageRoutes";

const Home = () => {
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">PresentationApp</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            {/* Other navigation links */}
          </Nav>
        </Container>
      </Navbar>

      <Container className="my-5">
        <Row className="align-items-center">
          <Col md={6}>
            <h1>Share Your Presentations Seamlessly</h1>
            <p className="lead">
              PresentationApp makes it easy to share and collaborate on
              presentations with your team, wherever they are.
            </p>
            <Button variant="primary" href={PageRoutes.Signin.path}>
              Get Started
            </Button>
          </Col>
          <Col md={6}>{/* Image or additional content here */}</Col>
        </Row>
      </Container>

      {/* Additional sections like features, testimonials, pricing, etc. */}
    </div>
  );
};

export default Home;
