import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import { getCookie, COOKIE_NAME } from "../utils/cookieUtils";
import { refreshToken } from "./useRefreshToken";

const useAxiosPrivate = () => {
  // const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use((config) => {
      if (!config.headers["Authorization"]) {
        const tokens = getCookie(COOKIE_NAME);
        const token = JSON.parse(tokens).accessToken;
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    });

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        if (
          error?.response?.status === 401 &&
          error?.response?.headers["token-expired"] &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const newAccessToken = await refreshToken();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  });

  return axiosPrivate;
};

export default useAxiosPrivate;
