import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import QRCodeOverlay from "../components/QRCodeOverlay";

export default () => {
  const [slideContent, setSlideContent] = useState(null);
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [showQRCodeOverlay, setShowQRCodeOverlay] = useState(false);
  const connection = useRef(null);

  const { slideDeckId } = useParams();

  useEffect(() => {
    connection.current = new HubConnectionBuilder()
      .withUrl("http://localhost:5000/hubs/presentation-hub")
      .withAutomaticReconnect()
      //.configureLogging(LogLevel.Trace)
      .build();

    const startConnection = async () => {
      try {
        await connection.current.start();
        console.log("Connected!");

        // Join the group for the slide deck
        await connection.current.invoke("JoinGroup", slideDeckId);
        console.log("Successfully joined group.");

        // Get the initial slide content
        connection.current
          .invoke("GetPresentation", slideDeckId)
          .then((response) => {
            handleViewUpdate(response);
          });

        // Set up the handler for receiving messages
        connection.current.on("UpdateView", (response) => {
          handleViewUpdate(response);
        });

        // Set up the handler for receiving QR code messages
        connection.current.on("ShowQRCode", (response) => {
          setQrCodeBase64(response);
          setShowQRCodeOverlay(
            (prevShowQRCodeOverlay) => !prevShowQRCodeOverlay
          );
        });
      } catch (e) {
        console.error("Connection failed: ", e);
      }
    };

    startConnection();

    return () => {
      console.log("Stopping connection");
      connection.current.stop();
    };
  }, []);

  const handleViewUpdate = (response) => {
    var jsonOutput = JSON.parse(response);
    if (jsonOutput?.imageUrl != null) {
      setSlideContent(jsonOutput);
    }
  };

  useEffect(() => {
    //console.log("Slide content updated: ", slideContent);
  }, [slideContent]);

  useEffect(() => {
    //console.log("Show Qr Code: ", showQRCodeOverlay);
  }, [showQRCodeOverlay]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {slideContent?.imageUrl ? (
          <img
            src={slideContent.imageUrl}
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              objectFit: "contain",
            }}
            alt="Slide"
          />
        ) : null}
      </div>
      <QRCodeOverlay show={showQRCodeOverlay} qrCodeBase64={qrCodeBase64} />
    </>
  );
};
