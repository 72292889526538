import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthProvider, AuthorizedRoute } from "./context/AuthContext";
import { PageRoutes } from "./pageRoutes";
import DashboardLayout from "./layouts/DashboardLayout";

import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import DashboardOverview from "./pages/DashboardOverview";
import EditSlides from "./pages/EditSlides";
import SlidePresenterView from "./pages/SlidePresenterView";
import SlideSpectatorView from "./pages/SlideSpectatorView";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: PageRoutes.Signin.path,
      element: <Signin />,
    },
    {
      path: PageRoutes.Signup.path,
      element: <Signup />,
    },
    {
      path: PageRoutes.SlideSpectatorView.path,
      element: <SlideSpectatorView />,
    },
    {
      element: <AuthorizedRoute />,
      errorElement: <h1>503</h1>,
      children: [
        {
          element: <DashboardLayout />,
          errorElement: <h1>404</h1>,
          children: [
            {
              path: PageRoutes.DashboardOverview.path,
              element: <DashboardOverview />,
            },
            {
              path: PageRoutes.EditSlides.path,
              element: <EditSlides />,
            },
            {
              path: PageRoutes.SlidePresenterView.path,
              element: <SlidePresenterView />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default App;
