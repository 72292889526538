import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default () => {
  const axiosPrivate = useAxiosPrivate();
  const [slideContent, setSlideContent] = useState({});
  const [input, setInput] = useState("");
  const connection = useRef(null);

  const { slideDeckId } = useParams();

  useEffect(() => {
    connection.current = new HubConnectionBuilder()
      .withUrl("http://localhost:5000/hubs/presentation-hub")
      .withAutomaticReconnect()
      //.configureLogging(LogLevel.Trace)
      .build();

    const startConnection = async () => {
      try {
        await connection.current.start();
        console.log("Connected!");

        // Join the group for the slide deck
        await connection.current.invoke("JoinGroup", slideDeckId);
        console.log("Successfully joined group.");

        connection.current
          .invoke("GetPresentation", slideDeckId)
          .then((response) => {
            var jsonOutput = JSON.parse(response);
            if (jsonOutput) {
              console.log("Setting slide content to: ", jsonOutput.imageUrl);
              setSlideContent(jsonOutput);
            }
          });

        // Set up the handler for receiving messages
        connection.current.on("UpdateView", (response) => {
          console.log("Received message: ", response);
          var jsonOutput = JSON.parse(response);

          if (jsonOutput.imageUrl != null) {
            setSlideContent(jsonOutput);
          }
        });
      } catch (e) {
        console.error("Connection failed: ", e);
      }
    };

    startConnection();

    return () => {
      connection.current.stop();
    };
  }, []);

  useEffect(() => {
    console.log("Slide content updated: ", slideContent);
  }, [slideContent]);

  const moveToNextSlide = async (value) => {
    axiosPrivate
      .put(`/presentation/${slideDeckId}/next`)
      .then((response) => {
        console.log(response.data);
        connection.current.invoke("UpdateSpectatorView", slideDeckId);
      })
      .catch((error) => {});
  };

  const moveToPreviousSlide = async (value) => {
    axiosPrivate
      .put(`/presentation/${slideDeckId}/previous`)
      .then((response) => {
        console.log(response.data);
        connection.current.invoke("UpdateSpectatorView", slideDeckId);
      })
      .catch((error) => {});
  };

  const showQRCode = async (value) => {
    axiosPrivate
      .put(`/presentation/${slideDeckId}/qrCode`)
      .then((response) => {
        console.log(response.data);
        connection.current.invoke("UpdateSpectatorView", slideDeckId);
      })
      .catch((error) => {});
  };

  return (
    <div>
      <h1>Presenter View</h1>

      <div>
        <img
          src={slideContent?.imageUrl}
          style={{ maxWidth: "500px", marginBottom: "50px" }}
        />
      </div>

      <div class="btn-group" role="group" aria-label="Basic example">
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => {
            moveToPreviousSlide();
          }}
        >
          Previous
        </button>
        <button type="button" class="btn btn-info" onClick={() => showQRCode()}>
          Show QR Code
        </button>
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => {
            moveToNextSlide();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
