import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PageRoutes } from "../pageRoutes";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default (props) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [slideDeck, setSlideDeck] = useState([]);

  useEffect(() => {
    axiosPrivate.get(`/account/slide-deck/${id}`).then((response) => {
      setSlideDeck(response.data);
      console.log(response.data);
    });
  }, []);

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  const handleOnDrag = (e, index) => {
    e.dataTransfer.setData("fromIndex", index);
  };

  const startPresentation = () => {
    console.log("Starting presentation...");
    axiosPrivate.post(`/Presentation/${id}/start`).then((response) => {
      const path = PageRoutes.SlidePresenterView.path.replace(
        ":slideDeckId",
        id
      );
      navigate(path);
    });
  };

  const handleOnDrop = (e, toIndex) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("fromIndex");
    moveSlide(fromIndex, toIndex);
  };

  const moveSlide = (fromIndex, toIndex) => {
    // Clone the current slides array
    let newSlides = [...slideDeck.slides];

    // Remove the element from its original position and store it
    const [removedElement] = newSlides.splice(fromIndex, 1);

    // Insert the element at its new position
    newSlides.splice(toIndex, 0, removedElement);

    // Update the slideDeck state with the new slides array
    setSlideDeck({ ...slideDeck, slides: newSlides });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <button
          href={PageRoutes.DashboardOverview.path}
          className="btn btn-outline-primary"
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => startPresentation()}
        >
          Start Presentation
        </button>
      </div>

      <div className="col-lg-6">
        {!isLoading &&
          slideDeck.slides.map((slide, index) => (
            <div
              key={JSON.stringify(slide)}
              className="card mb-3"
              draggable
              onDragStart={(e) => handleOnDrag(e, index)}
              onDragOver={handleOnDragOver}
              onDrop={(e) => handleOnDrop(e, index)}
            >
              <div className="row g-0">
                <div className="col-lg-8">
                  <img
                    src={slide.imageUrl}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-lg-4">
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">
                      This is a wider card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p>
                    <p className="card-text">
                      <small className="text-muted">
                        Last updated 3 mins ago
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="col-lg-6">
        <p>
          Drag a slide and drop it onto or in place of another slide to reorder
          them. This action will rearrange the slides in the order you prefer,
          allowing you to customize the sequence of your presentation easily
        </p>
      </div>
    </>
  );
};
