import React, { createContext, useContext, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getCookie, COOKIE_NAME } from "../utils/cookieUtils";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedTokens = getCookie(COOKIE_NAME);
    if (!storedTokens) return;

    const parsedTokens = JSON.parse(storedTokens);

    if (!parsedTokens.accessToken) return;

    // Split the JWT token into its three parts: Header, Payload, and Signature
    const parts = parsedTokens.accessToken.split(".");

    // Check if there are exactly three parts
    if (parts.length !== 3) {
      throw new Error("Invalid JWT token format");
    }

    // Decode the base64-encoded payload (second part)
    const user = JSON.parse(atob(parts[1]));

    // 'user' now contains the claims as key-value pairs
    return user;
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthorizedRoute = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth)
    return (
      <Navigate to={{ pathname: "/sign-in", state: { from: location } }} />
    );

  return <Outlet />;
};
