import axios from "axios";

const BASE_URL =
  process.env.API_BASE_URL ||
  process.env.REACT_APP_API_BASE_URL ||
  "http://localhost:5000";

export default axios.create({
  baseURL: BASE_URL + "/api/",
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL + "/api/",
  headers: { "Content-Type": "application/json" },
});
