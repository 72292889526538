import React from "react";
import { PageRoutes } from "../pageRoutes";

export default (props) => {
  const getPath = (path, id) => path.replace(":id", id);

  return (
    <div className="card" style={{ width: "18rem" }}>
      <img
        src={
          props.slideDeck.slides[0]?.imageUrl ??
          "https://placehold.co/286x180?text=Unavailable"
        }
        className="card-img-top"
        alt="..."
      />
      <div className="card-body">
        <h5 className="card-title">Card title</h5>
        <p className="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
        <a
          href={`${getPath(PageRoutes.EditSlides.path, props.slideDeck.id)}`}
          className="btn btn-primary"
        >
          Edit
        </a>
      </div>
    </div>
  );
};
