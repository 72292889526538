import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
} from "@themesberg/react-bootstrap";

import SlideDecksView from "../components/SlideDecksView";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const DashboardOverview = () => {
  const axiosPrivate = useAxiosPrivate();

  const [slideDecks, setSlideDecks] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fetchSlideDecks = () => {
    axiosPrivate.get("/account/slide-decks").then((response) => {
      setSlideDecks(response.data);
      console.log(response.data);
    });
  };

  useEffect(() => {
    fetchSlideDecks();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name.toLowerCase();
      if (!fileName.toLowerCase().endsWith(".pdf")) {
        alert("Invalid file format. Please upload a PDF file.");
      }

      console.log("Uploading file...", file);
      const formData = new FormData();
      formData.append("file", file);

      axiosPrivate
        .post("/account/slide-deck", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          fetchSlideDecks();
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          setSelectedFile(null);
        });
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <ButtonGroup>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleButtonClick}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Upload New Presentation
          </Button>
        </ButtonGroup>
      </div>

      <div className="row">
        {slideDecks.length === 0 ? (
          <div className="text-center">
            <h2>No slide decks found.</h2>
          </div>
        ) : (
          slideDecks.map((slideDeck, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4">
              <SlideDecksView slideDeck={slideDeck} />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default DashboardOverview;
